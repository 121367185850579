export const useHelperStore = defineStore('helper', {
    actions: {
        capitalize(string) {
            return _.capitalize(string);
        },
        headline(str) {
            if (str == null) return '';
            str = str.replaceAll('-', ' ').replaceAll('_', ' ');
            str = str.replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/^./, function (match) {
                    return match.toUpperCase();
                });
            str.toLowerCase();
            return str.replace(/\b\w/g, function (match) {
                return match.toUpperCase();
            });
        },
        parseUrl(input) {
            const params = new URLSearchParams();
            for (const key in input) {
                if (Array.isArray(input[key])) {
                    input[key].forEach(val => {
                        if (val != null || val != "null")
                            params.append(key + '[]', val)
                    })
                } else {
                    params.append(key, input[key]);
                }
            }

            return "?" + params.toString();
        },
        sort(valuePath, array) {
            let path = valuePath.split('.')

            return array.sort((a, b) => {
                return getValue(b, path) - getValue(a, path)
            });

            function getValue(obj, path) {
                path.forEach(path => obj = obj[path])
                return obj;
            }
        },
        numberFormat(number, decimals = 0, dec_point = ".", thousands_sep = ",") {
            var n = number,
                c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals,
                d = dec_point == undefined ? "." : dec_point,
                t = thousands_sep == undefined ? "," : thousands_sep,
                s = n < 0 ? "-" : "",
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
    }
});
