<script>
import Auth from '~/components/layouts/Auth.vue';
import Dashboard from '~/components/layouts/Dashboard.vue';
import Default from '~/components/layouts/Default.vue';

export default {
	computed: {
		...mapState(useLayoutStore, ['layout']),
		...mapGetters(useAuthStore, ['hasSession']),
		...mapGetters(useResponseStore, ['htmlError', 'errors']),
	},
	components: {
		'auth': Auth,
		'dashboard': Dashboard,
		'default': Default,
	},
	methods: {
		...mapActions(useAuthStore, ['loadSession']),
		...mapActions(useModuleStore, ['getModule']),
		...mapActions(useVersionStore, ['getVersion']),
	},
	created() {
		this.loadSession();
		this.getModule();
		this.getVersion();
	},
	watch: {
		errors: function () {
			if (this.errors != null) {
				this.$swal({
					icon: 'error',
					position: 'center',
					showConfirmButton: false,
					title: 'Terjadi Kesalahan!',
					html: this.htmlError,
					timer: 3000,
				});
			}
		}
	}
}
</script>

<template>
	<component :is="layout"></component>
</template>
