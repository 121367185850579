<script>
export default {
	props: {
		type: String,
	},
	setup() {
		const auth = useAuthStore();
		const can = auth.can;
		return { can };
	},
	data() {
		return {
			navigations: [
				{
					title: 'Jurnal Connect',
					permission: 'module.jurnal.connect',
					children: [
						{
							title: 'Jurnal Connect',
							link: '/dashboard/jurnal-connects',
							icon: 'LinkIcon',
							permission: 'module.jurnal.connect',
						},
						{
							title: 'Product Order',
							link: '/dashboard/jurnal-connects/product-orders',
							icon: 'CookieIcon',
							permission: 'module.jurnal.connect.product.order',
						},
						{
							title: 'Product Opname',
							link: '/dashboard/jurnal-connects/product-opnames',
							icon: 'ChecklistIcon',
							permission: 'module.jurnal.connect.product.opname',
						},
						{
							title: 'Warehouse Inventory',
							link: '/dashboard/jurnal-connects/warehouse-inventories',
							icon: 'PackagesIcon',
							permission: 'module.jurnal.connect.warehouse.inventory',
						},

						{
							title: 'Mapping Brand',
							link: '/dashboard/jurnal-connects/brands',
							icon: 'BarcodeIcon',
							permission: 'module.jurnal.connect.brand',
						},

						{
							title: 'Mapping Packaging',
							link: '/dashboard/jurnal-connects/packagings',
							icon: 'PackageIcon',
							permission: 'module.jurnal.connect.packaging',
						},

						{
							title: 'History Inventory',
							link: '/dashboard/jurnal-connects/warehouse-inventory-histories',
							icon: 'HistoryIcon',
							permission: 'module.jurnal.connect.warehouse.inventory.history',
						},
						{
							title: 'Reset Transaction',
							link: '/dashboard/jurnal-connects/reset-transactions',
							icon: 'RefreshAlertIcon',
							permission: 'module.jurnal.connect.reset.transaction',
						},
						//add link here ...






					],
				},
				//add more nav here ...
			]
		}
	}
}
</script>

<template>
	<template v-for="(tab, index) in navigations" :key="index">
		<li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
			<span class="navigation-side-label"><b>{{ tab.title }}</b></span>
		</li>
		<template v-for="tab in tab.children" :key="tab.link">
			<li v-if="can(`${tab.permission}-index`)" class="link" :class="{ 'active': $route.path == tab.link }">
				<RouterLink :to="tab.link">
					<span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
						<component v-bind:is="tab.icon" />
					</span>
					<span class="navigation-side-label">{{ tab.title }}</span>
				</RouterLink>
			</li>
		</template>
	</template>
</template>
