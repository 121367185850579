import App from "~/App.vue";
import Router from "~/router";
import 'sweetalert2/dist/sweetalert2.min.css';
import { createI18n } from 'vue-i18n';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import * as Sentry from "@sentry/vue";
import messages from '@intlify/unplugin-vue-i18n/messages'

const pinia = createPinia();
const i18n = createI18n({ locale: 'id', messages, missingWarn: false, fallbackWarn: false })
const app = createApp({ extends: App, created() { } });

if (!import.meta.env.VITE_APP_DEBUG) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(Router),
            }),
            // new Sentry.Replay(),
        ],
        tracePropagationTargets: ["favori.web.id", "localhost"],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
    Sentry.setUser(null);
}


app.use(pinia)
    .use(i18n)
    .use(Router)
    .use(VueApexCharts)
    .use(VueTablerIcons)
    .use(VueSweetalert2)
    //.use(Vue3Html2pdf)
    .component('chart', VueApexCharts)
    .component('downloadCsv', JsonCSV)
    .component('QuillEditor', QuillEditor)
    .component("v-select", vSelect)
    .component('ContentLoader', ContentLoader)
    .component('FacebookLoader', FacebookLoader)
    .component('CodeLoader', CodeLoader)
    .component('BulletListLoader', BulletListLoader)
    .component('InstagramLoader', InstagramLoader)
    .component('ListLoader', ListLoader)
    .component(VueQrcode.name, VueQrcode)
    .mount("#app");
