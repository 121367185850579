<script>
export default {
    props: {
        type: String,
    },
    setup() {
        const auth = useAuthStore();
        const can = auth.can;
        return { can };
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Permintaan Barang',
                    permission: 'module.sales.order',
                    children: [
                        {
                            title: 'Ajukan Permintaan',
                            link: '/dashboard/sales-orders/pre-orders',
                            icon: 'TruckDeliveryIcon',
                            permission: 'module.sales.order.pre.order',
                        },
                        {
                            title: 'List Permintaan',
                            link: '/dashboard/sales-orders',
                            icon: 'ClipboardListIcon',
                            permission: 'module.sales.order',
                        },
                        {
                            title: 'List BKBJ',
                            link: '/dashboard/sales-orders/internals',
                            icon: 'ChecklistIcon',
                            permission: 'module.sales.order',
                        },
                        {
                            title: 'List Delivery',
                            link: '/dashboard/sales-orders/deliveries',
                            icon: 'TirIcon',
                            permission: 'module.sales.order',
                        },

                        {
                            title: 'Approval Limit',
                            link: '/dashboard/sales-orders/approval-limits',
                            icon: 'FileLikeIcon',
                            permission: 'module.sales.order.approval.limit',
                        },

                        {
                            title: 'Distributor List',
                            link: '/dashboard/sales-orders/distributors',
                            icon: 'AffiliateIcon',
                            permission: 'module.sales.order.distributor',
                        },
                        //add link here ...




                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <template v-for="(tab, index) in navigations" :key="index">
        <li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
            <span class="navigation-side-label"><b>{{ tab.title }}</b></span>
        </li>
        <template v-for="tab in tab.children" :key="tab.link">
            <li v-if="can(`${tab.permission}-index`)" class="link" :class="{ 'active': $route.path == tab.link }">
                <RouterLink :to="tab.link">
                    <span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
                        <component v-bind:is="tab.icon" />
                    </span>
                    <span class="navigation-side-label">{{ tab.title }}</span>
                </RouterLink>
            </li>
        </template>
    </template>
</template>
