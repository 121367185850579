<script>

export default {
	setup() {
		const auth = useAuthStore();
		const notification = useNotificationStore();
		return { auth, notification }
	},
	computed: {
		...mapGetters(useAuthStore, ['hasSession']),
		...mapGetters(useResponseStore, ['htmlError', 'errors']),
	},
	created() {
		if (!this.hasSession) {
			this.$router.push('/auths/login');
		}
	},
	methods: {
		...mapActions(useAuthStore, ['loadSession']),
		popupNotif(eventName, data) {
			if (data.title) {
				this.notification.fetchAll();
				this.$swal({
					icon: 'success',
					position: 'bottom-end',
					showConfirmButton: false,
					title: data.title,
					html: data.message,
					timer: 5000,
					toast: true,
				});
			}
		}
	},
	mounted() {
		this.loadSession();
		if (!this.auth) return;
		if (!this.auth.token) return;
		if (!this.auth.user) return;

		var pusher = new Pusher('a231f4a9e3acea66d9ee', {
			cluster: 'ap1',
			channelAuthorization: {
				endpoint: "/broadcasting/auth",
				headers: { "Authorization": "Bearer " + this.auth.token },
			}
		});

		var channel = pusher.subscribe('private-App.' + this.auth.user.id);
		channel.bind_global(this.popupNotif);
	},
}
</script>
<template>
	<div>
		<DashboardsHeader />
		<DashboardsNavigation />
		<div class="container show margin-bottom-large" id="container">
			<RouterView />
			<div style="height: 60px;" class="uk-hidden@m"></div>
		</div>
		<DashboardsFooter />
	</div>
</template>
