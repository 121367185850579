import AES from 'crypto-js/aes';
import MD5 from 'crypto-js/md5';
import encUTF8 from 'crypto-js/enc-utf8';

var isDev = import.meta.env.VITE_APP_DEBUG;

export function $getStorage(key) {
	var encKey = isDev ? key : MD5(key).toString();
	var storage = localStorage.getItem(encKey);
	var decValue = storage ? isDev ? storage : AES.decrypt(storage, encKey).toString(encUTF8) : null;
	decValue = (decValue == "undefined") ? null : decValue;
	return decValue ? JSON.parse(decValue) : null;
}

export function $setStorage(key, value) {
	var encKey = isDev ? key : MD5(key).toString();
	var encValue = isDev ? JSON.stringify(value) : AES.encrypt(JSON.stringify(value), encKey).toString();
	localStorage.setItem(encKey, encValue);
}

export function $getSession(key) {
	var encKey = isDev ? key : MD5(key).toString();
	var storage = sessionStorage.getItem(encKey);
	var decValue = storage ? isDev ? storage : AES.decrypt(storage, encKey).toString(encUTF8) : null;
	decValue = (decValue == "undefined") ? null : decValue;
	return decValue ? JSON.parse(decValue) : null;
}

export function $setSession(key, value) {
	var encKey = isDev ? key : MD5(key).toString();
	var encValue = isDev ? JSON.stringify(value) : AES.encrypt(JSON.stringify(value), encKey).toString();
	sessionStorage.setItem(encKey, encValue);
}
