import $axios from '~/helpers/axios';

export const useVersionStore = defineStore('version', {
	state: () => ({
		version: null
	}),

	actions: {
		getVersion() {
			const response = useResponseStore();
			return new Promise((resolve, reject) => {
				$axios.get('/version')
					.then(res => {
						if (res.data.success == true) {
							this.version = res.data.data;
							response.setStorage('version', this.version);
						}
						resolve(res.data)
					})
			})
		},
		setVersion(payload) {
			this.version = payload
		}
	},
});
