<script>
export default {
	props: {
		type: String,
	},
	setup() {
		const auth = useAuthStore();
		const can = auth.can;
		return { can };
	},
	data() {
		return {
			navigations: [
				{
					title: 'Material Promo',
					permission: 'module.material.promotion',
					children: [
						{
							title: 'Transaction',
							link: '/dashboard/material-promotions/transactions',
							icon: 'FileInvoiceIcon',
							permission: 'module.material.promotion.transaction',
						},
						{
							title: 'Inventory',
							link: '/dashboard/material-promotions/inventories',
							icon: 'PackagesIcon',
							permission: 'module.material.promotion.inventory',
						},
						{
							title: 'Approval',
							link: '/dashboard/material-promotions/approvals',
							icon: 'FileLikeIcon',
							permission: 'module.material.promotion.approval',
						},
						{
							title: 'Stock Opname',
							link: '/dashboard/material-promotions/stock-opnames',
							icon: 'GitCompareIcon',
							permission: 'module.material.promotion.stock.opname',
						},
						{
							title: 'Jenis SO',
							link: '/dashboard/material-promotions/stock-opnames/types',
							icon: 'CategoryIcon',
							permission: 'module.material.promotion.stock.opname.type',
						},

						{
							title: 'Item',
							link: '/dashboard/material-promotions/items',
							icon: 'BarcodeIcon',
							permission: 'module.material.promotion.item',
						},
						{
							title: 'Category',
							link: '/dashboard/material-promotions/categories',
							icon: 'PackageIcon',
							permission: 'module.material.promotion.category',
						},
						{
							title: 'Principle',
							link: '/dashboard/material-promotions/principles',
							icon: 'TruckLoadingIcon',
							permission: 'module.material.promotion.principle',
						},


						//add link here ...




					],
				},
				//add more nav here ...
			]
		}
	}
}
</script>

<template>
	<template v-for="(tab, index) in navigations" :key="index">
		<li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
			<span class="navigation-side-label"><b>{{ tab.title }}</b></span>
		</li>
		<template v-for="tab in tab.children" :key="tab.link">
			<li v-if="can(`${tab.permission}-index`)" class="link" :class="{ 'active': $route.path == tab.link }">
				<RouterLink :to="tab.link">
					<span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
						<component v-bind:is="tab.icon" />
					</span>
					<span class="navigation-side-label">{{ tab.title }}</span>
				</RouterLink>
			</li>
		</template>
	</template>
</template>
