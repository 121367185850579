<script>
export default {
    props: {
        type: String,
    },
    setup() {
        const auth = useAuthStore();
        const can = auth.can;
        return { can };
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Timesheet',
                    permission: 'module.timesheet',
                    children: [
                        {
                            title: 'Timesheet',
                            link: '/dashboard/timesheets',
                            icon: 'ReportIcon',
                            permission: 'module.timesheet',
                        },
                        {
                            title: 'Penugasan',
                            link: '/dashboard/timesheets/assignments',
                            icon: 'SubtaskIcon',
                            permission: 'module.timesheet.assignment',
                        },
                        {
                            title: 'Status',
                            link: '/dashboard/timesheets/statuses',
                            icon: 'StatusChangeIcon',
                            permission: 'module.timesheet.status',
                        },

                        {
                            title: 'Jenis',
                            link: '/dashboard/timesheets/types',
                            icon: 'FileTypographyIcon',
                            permission: 'module.timesheet.type',
                        },

                        {
                            title: 'Approval',
                            link: '/dashboard/timesheets/approvals',
                            icon: 'FileLikeIcon',
                            permission: 'module.timesheet.approval',
                        },

                        // {
                        //     title: 'Timesheet Comment',
                        //     link: '/dashboard/timesheets/comments',
                        //     icon: 'AppsIcon',
                        //     permission: 'module.timesheet.comment',
                        // },

                        //add link here ...
                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <template v-for="(tab, index) in navigations" :key="index">
        <li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
            <span class="navigation-side-label"><b>{{ tab.title }}</b></span>
        </li>
        <template v-for="tab in tab.children" :key="tab.link">
            <li v-if="can(`${tab.permission}-index`)" class="link" :class="{ 'active': $route.path == tab.link }">
                <RouterLink :to="tab.link">
                    <span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
                        <component v-bind:is="tab.icon" />
                    </span>
                    <span class="navigation-side-label">{{ tab.title }}</span>
                </RouterLink>
            </li>
        </template>
    </template>
</template>
