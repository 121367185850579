<script>
export default {
    setup() {
        const auth = useAuthStore();
        return { auth };
    },
}
</script>

<template>
    <footer class="uk-hidden@s uk-position-bottom uk-padding-medium uk-width-expand uk-background-default"
        style="z-index: 10;">
        <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar>
            <div class="uk-navbar-center ">
                <ul class="uk-navbar-nav uk-text-center">
                    <li>
                        <div>
                            <RouterLink to="/dashboard/menu"
                                class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                <Menu2Icon class="uk-padding-remove uk-margin-remove" />
                            </RouterLink>
                            <div class="uk-navbar-subtitle">Menu</div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <RouterLink to="/" class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                <DashboardIcon class="uk-padding-remove uk-margin-remove" />
                            </RouterLink>
                            <div class="uk-navbar-subtitle">Home</div>
                        </div>
                    </li>
                    <template v-if="auth.hasRoles('Distributor / Agent')">
                        <li>
                            <div>
                                <RouterLink to="/dashboard/sales-orders/pre-orders"
                                    class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                    <SquarePlusIcon class="uk-padding-remove uk-margin-remove" />
                                </RouterLink>
                                <div class="uk-navbar-subtitle">PO Baru</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <RouterLink to="/dashboard/sales-orders"
                                    class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                    <ReportIcon class="uk-padding-remove uk-margin-remove" />
                                </RouterLink>
                                <div class="uk-navbar-subtitle">List</div>
                            </div>
                        </li>
                    </template>
                    <template v-else>
                        <li>
                            <div>
                                <RouterLink to="/dashboard/timesheets/create"
                                    class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                    <SquarePlusIcon class="uk-padding-remove uk-margin-remove" />
                                </RouterLink>
                                <div class="uk-navbar-subtitle">Task Baru</div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <RouterLink to="/dashboard/timesheets"
                                    class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                    <ReportIcon class="uk-padding-remove uk-margin-remove" />
                                </RouterLink>
                                <div class="uk-navbar-subtitle">List</div>
                            </div>
                        </li>
                    </template>
                    <li>
                        <div>
                            <RouterLink to="/dashboard/settings/profile"
                                class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                <UserIcon class="uk-padding-remove uk-margin-remove" />
                            </RouterLink>
                            <div class="uk-navbar-subtitle">Profile</div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </footer>
</template>
