<script>
export default {
    computed: {
        ...mapGetters(useAuthStore, ['hasSession']),
        ...mapGetters(useResponseStore, ['htmlError', 'errors']),
    },
    created() {
        if (this.hasSession) {
            this.$router.push('/dashboard');
        }
    },
    data() {
        return {
            data: {
                email: '',
                password: '',
                remember_me: false
            },
            slide: [
                {
                    'title': import.meta.env.VITE_APP_NAME,
                    'description': import.meta.env.VITE_APP_DESC,
                    'image': '/images/login-bg.jpg'
                }
            ]
        }
    },
}
</script>

<template>
    <div class="uk-section uk-padding-remove uk-background-default">
        <div uk-height-viewport="offset-top: true; offset-bottom: true" class="uk-grid-collapse" uk-grid>
            <div class="uk-width-expand uk-width-1-3@m uk-flex uk-flex-column">
                <div class="uk-width-expand uk-padding uk-flex uk-flex-middle">
                    <RouterView />
                </div>
                <div class="uk-width-1-1 uk-flex uk-flex-center">
                    <p class="uk-text-small uk-text-center uk-margin-small uk-padding-small">
                        2022 © All right reserved.<br />
                        <RouterLink to="/">Kembali ke Halaman Utama</RouterLink>
                    </p>
                </div>
            </div>
            <div class="uk-width-2-3 uk-inline uk-visible@m">
                <div>
                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1"
                        uk-slideshow="animation: pull;autoplay: true">
                        <ul class="uk-slideshow-items" uk-height-viewport="offset-top: true; offset-bottom: true">
                            <li v-for="(data, index) in slide" :key="index">
                                <img :src="data.image" alt="" uk-cover>
                                <div
                                    class="uk-overlay uk-overlay-primary uk-position-bottom uk-text-center uk-transition-slide-bottom">
                                    <h3 class="uk-margin-remove">{{ data.title }}</h3>
                                    <p class="uk-margin-remove">
                                        {{ data.description }}
                                    </p>
                                </div>
                            </li>
                        </ul>

                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#"
                            uk-slidenav-previous uk-slideshow-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
                            uk-slideshow-item="next"></a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
