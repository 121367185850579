import { createRouter, createWebHistory } from 'vue-router';
import generatedRoutes from '~pages';

const Router = createRouter({
    history: createWebHistory(),
    routes: generatedRoutes,  // config routes
    //linkActiveClass: 'active',
});




Router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
        setTimeout(() => {
            window.location.reload(true);
        }, 2000);
    }
});

export default Router;
