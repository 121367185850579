import $axios from '~/helpers/axios';

const data = {
    data: null,
    readAt: null,
    createdAt: null,
    total: null
};

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        moduleName: 'Notification',
        permission: 'notification',
        apiRoute: '/notifications',
        route: '/dashboard/notifications',
        data: data,
        list: null,
        create: false,
        edit: false,
        view: true,
        loading: true,
    }),

    actions: {
        fetchAll() {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute).then(res => {
                    if (res.data.success == true) {
                        this.list = res.data.data;
                        this.total = res.data.total;
                        response.clearErrors();
                    }
                    resolve(res.data)
                })
                this.loading = false;
            })
        },
        fetchData(payload) {
            this.loading = true;
            this.setView();
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute + '/' + payload).then(res => {
                    this.data = res.data.data;
                    response.clearErrors();
                    resolve(res.data)
                })
                this.loading = false;
            })
        },
        markAsRead(payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios({
                    method: 'PUT',
                    url: this.apiRoute + '/' + payload,
                }).then(res => {
                    //this.data = res.data.data;
                    resolve(res.data)
                    response.clearErrors();
                })
                this.loading = false;
            })
        },
        remove(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.delete(this.apiRoute + '/' + payload).then(res => {
                    this.data = null;
                    resolve(res.data)
                    response.clearErrors();
                })
            })
        },
        rollBack() {
            this.fetchData(this.data.id);
            this.setView();
        },
        setEdit() {
            this.edit = true;
            this.view = false;
            this.create = false;
        },
        setView() {
            this.edit = false;
            this.view = true;
            this.create = false;
        },
        setCreate() {
            this.edit = false;
            this.view = false;
            this.create = true;
            this.data = null;
            this.loading = false;
        },
        setLoading(payload) {
            this.loading = payload;
        }
    },

    getters: {
        isCreate() {
            return this.create ? true : false;
        },
        isEdit() {
            return this.edit ? true : false;
        },
        isView() {
            return this.view ? true : false;
        }
    }

});
