<script>

export default {
	setup() {
		const auth = useAuthStore();
		const layout = useLayoutStore();
		const version = useVersionStore();
		return { auth, layout, version }
	},
	methods: {
		endSession() {
			this.auth.logout()
			this.$router.push('/auths/login')
		},
	},
};
</script>

<template>
	<div class="navigation-top" uk-toggle="target: .navigation-top; cls: uk-background-default;  mode:media; media: @l">
		<div class="uk-navbar-container uk-navbar-transparent" uk-navbar="mode: click">
			<div class="uk-navbar-left uk-margin-small-left">
				<ul class="uk-navbar-nav" style="gap: 0px !important;">
					<li>
						<div class="logo">
							<img src="/images/logo.png" alt="Logo" />
						</div>
					</li>
					<li style="padding-top: 5px;">
						<span class="uk-dark uk-text-small brand uk-text-uppercase">
							<b>{{ layout.appName }}</b>
							<br />
							<template v-if="version.version">
								<small>Ver. {{ version.version.version }}</small>
							</template>
						</span>
					</li>
				</ul>
			</div>
			<div class="uk-navbar-right">
				<ul class="uk-navbar-nav" style="gap: 0px;">
					<li>
						<button v-if="auth.user" class="uk-button uk-button-small uk-button-default uk-padding-remove">
							<span class="uk-dark uk-text-small uk-margin-small-right uk-text-capitalize uk-visible@s">
								{{ auth.user.name }}
							</span>
							<img v-if="auth.user.profile_picture" class="initial-name bg-color-x"
								:src="auth.user.profile_picture" />
							<span v-else class="initial-name bg-color-x">
								{{ auth.user.name_initial }}
							</span>
						</button>
						<div uk-dropdown="mode: click;" class="uk-width-small">
							<img v-if="auth.user && auth.user.profile_picture" :src="auth.user.profile_picture"
								class="profile-picture" />
							<hr class="uk-margin-small-bottom uk-margin-small-top" />
							<div class="uk-width-1-1 uk-flex uk-flex-center">
								<div v-for="val in auth.roles" :key="val" class="uk-badge uk-text-capitalize">
									{{ val }}
								</div>
							</div>
							<hr class="uk-margin-remove-bottom uk-margin-small-top" />
							<ul class="uk-nav uk-dropdown-nav">
								<li>
									<RouterLink to="/dashboard/settings/profile" class="uk-padding-remove-vertical">
										<span class="navigation-side-icon material-icons md-20">
											<EditIcon />
										</span>
										<span class="navigation-side-label">Profile</span>
									</RouterLink>
								</li>
								<li class="uk-nav-divider"></li>
								<li>
									<button @click="endSession"
										class="uk-margin-auto-vertical	uk-button uk-button-primary red uk-width-1-1">
										<span class="navigation-side-icon material-icons md-20">
											<LogoutIcon />
										</span>
										<span class="navigation-side-label">Logout</span>
									</button>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<DashboardsNotification />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style scoped>
.profile-picture {
	width: 150px;
	height: 150px;
	border-radius: 100px;
}
</style>
