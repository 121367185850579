import $axios from '~/helpers/axios';
import { jwtDecode } from "jwt-decode";
import * as Sentry from '@sentry/vue';


export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: null,
        version: null,
        permission: null,
        role: null,
    }),

    actions: {
        login(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.post('/auths/login', payload)
                    .then(res => {
                        if (res.data.success == true) {
                            this.token = res.data.data.token;
                            this.version = res.data.data.version;
                            response.setStorage('token', this.token);
                            response.setStorage('version', this.version);
                            this.role = this.roles;
                            response.setStorage('roles', this.role);
                            this.getPermission();
                        }
                        resolve(res.data)
                    }).catch(error => {
                    })
            })
        },

        register(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.post('/auths/register', payload)
                    .then(res => {
                        if (res.data.success == true) {
                            this.token = res.data.data.token;
                            this.version = res.data.data.version;
                            response.setStorage('token', this.token);
                            response.setStorage('version', this.version);
                            this.role = this.roles;
                            response.setStorage('roles', this.role);
                            this.getPermission();
                        }
                        resolve(res.data)
                    }).catch(error => {
                    })
            })
        },

        requestPassword(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/passwords/forgot', payload)
                    .then(res => {
                        resolve(res.data)
                    }).catch(error => {
                    })
            })
        },

        resetPassword(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.post('/auths/passwords/reset', payload)
                    .then(res => {
                        if (res.data.success == true) {
                            this.token = res.data.data.token;
                            this.version = res.data.data.version;
                            response.setStorage('token', this.token);
                            response.setStorage('version', this.version);
                            this.role = this.roles;
                            response.setStorage('roles', this.role);
                            this.getPermission();
                        }
                        resolve(res.data)
                    }).catch(error => { })
            })
        },

        validateToken(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/passwords/validate', payload)
                    .then(res => {
                        resolve(res.data)
                    }).catch(error => { })
            })
        },

        logout() {
            this.token = null;
            this.version = null;
            this.permission = null;
            this.role = null;
            localStorage.clear();
            sessionStorage.clear();
        },
        getPermission() {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get('/auths/permission')
                    .then(res => {
                        if (res.data.success == true) {
                            this.permission = res.data.data;
                            response.setStorage('permissions', this.permission);
                            Sentry.setUser({
                                id: this.user.id,
                                email: this.user.email
                            });
                        }
                        resolve(res.data)
                    })
            })
        },
        loadSession() {
            const response = useResponseStore();
            this.token = response.getStorage('token');
            this.version = response.getStorage('version');
            if (this.token && response.getStorage('permissions') == null) {
                this.getPermission()
            }
            this.permission = response.getStorage('permissions');
        }
    },

    getters: {
        authToken(state) {
            return state.token;
        },
        hasSession(state) {
            return state.token ? true : false;
        },
        user(state) {
            return state.token ? jwtDecode(state.token).user : null;
        },
        roles(state) {
            return state.token ? jwtDecode(state.token).roles : null;
        },
        hasRoles: (state) => (roles) => {
            const can = state.roles ? state.roles.find(val => val === roles) : null;
            return can ? true : false;
        },
        permissions(state) {
            return state.permission;
        },
        can: (state) => (permission) => {
            const can = state.permission ? state.permission.find(val => val === permission) : null;
            return can ? true : false;
        }
    }
});
