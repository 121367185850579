<script>
export default {
    props: {
        type: String,
    },
    setup() {
        const { t } = useI18n();
        const auth = useAuthStore();
        const can = auth.can;
        return { can, t };
    },
    data() {
        return {
            navigations: [
                {
                    title: this.t('Manufacture / Production'),
                    permission: 'module.production',
                    children: [
                        {
                            title: this.t('Order'),
                            link: '/dashboard/productions/orders',
                            icon: 'CookieIcon',
                            permission: 'module.production.order',
                        },

                        {
                            title: this.t('Label'),
                            link: '/dashboard/productions/labels',
                            icon: 'TagsIcon',
                            permission: 'module.production.label',
                        },

                        {
                            title: this.t('Stock Register'),
                            link: '/dashboard/productions/stock-registers',
                            icon: 'ArchiveIcon',
                            permission: 'module.production.stock.register',
                        },

                        {
                            title: this.t('Approval'),
                            link: '/dashboard/productions/order-approvals',
                            icon: 'FileLikeIcon',
                            permission: 'module.production.order.approval',
                        },

                        //add link here ...
                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <template v-for="(tab, index) in navigations" :key="index">
        <li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
            <span class="navigation-side-label"><b>{{ tab.title }}</b></span>
        </li>
        <template v-for="tab in tab.children" :key="tab.link">
            <li v-if="can(`${tab.permission}-index`)" class="link" :class="{ 'active': $route.path == tab.link }">
                <RouterLink :to="tab.link">
                    <span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
                        <component v-bind:is="tab.icon" />
                    </span>
                    <span class="navigation-side-label">{{ tab.title }}</span>
                </RouterLink>
            </li>
        </template>
    </template>
</template>
