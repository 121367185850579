import axios from 'axios';

let headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
};

const $axios = axios.create({
    baseURL: import.meta.env.VITE_API_ENDPOINT,
    withCredentials: true,
    headers: headers
});

//Add Bearer Token
$axios.interceptors.request.use(config => {
    const auth = useAuthStore();
    const response = useResponseStore();
    response.startRequest();
    config.headers.Authorization = 'Bearer ' + auth.token;
    return config;
});

//Add Middleware if Bearer Token Expired / Forbidden Auth
$axios.interceptors.response.use(response => {
    const responseStore = useResponseStore();
    responseStore.endRequest();
    return response;
}, error => {
    const auth = useAuthStore();
    const response = useResponseStore();
    response.endRequest();
    if (error) {
        if (error.code == 400 || (error.response && error.response.status == 400)) {
            if (error.response && error.response.data.message.error) {
                response.setErrors(error.response.data.message.error);
            } else if (error.message.error) {
                response.setErrors(error.message.error);
            };
        } else if (error.response) {
            switch (error.response.status) {
                case 401:
                    auth.logout();
                    document.location.href = "/auths/login";
                    break;
                case 403:
                    response.setErrors({ server: "This action is unauthorized" });
                    break;
                case 404:
                    //document.location.href = "/not-found";
                    break;
                case 500:
                    if (!error.response.data) {
                        response.setErrors({ server: error.message });
                        return Promise.reject(error.message);
                    }
                    if (!error.response.data.message) {
                        response.setErrors({ server: error.message });
                        return Promise.reject(error.message);
                    }
                    if (!error.response.data.message.error) {
                        response.setErrors({ server: error.message });
                        return Promise.reject(error.message);
                    } else {
                        response.setErrors({ server: error.response.data.message.error });
                        return Promise.reject(error.message);
                    }
                    break;
                case 503:
                    location.reload();
                    break;
                default:
                    break;
            }
            return Promise.reject(error.message);
        }
        return Promise.reject(error.message);
    }
});

export default $axios;
