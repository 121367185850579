<template>
	<nav uk-navbar class="navigation-side uk-navbar-container uk-navbar-left uk-navbar-transparent show"
		uk-toggle="target: .navigation-side, .container; cls: show;  mode:media; media: @s">
		<div class="navigation-side-toggle uk-padding-small uk-padding-remove-horizontal uk-margin-remove-top">
			<div class="uk-grid-collapse" uk-grid>
				<div class="uk-width-auto">
					<button
						uk-toggle="target: .navigation-side, .container; cls: collapsed;  animation: uk-animation-slide-right-medium;"
						type="button" class="button-menu uk-button uk-button-default uk-button-icon uk-width-1-1">
						<LayoutDashboardIcon />
					</button>
				</div>
				<div class="uk-width-expand">
					<RouterLink to="/dashboard"
						class="button-add uk-button uk-button-default uk-preserve-width uk-padding-remove uk-border-rounded">
						<div class="uk-grid-collapse frame uk-border-rounded" uk-grid>
							<div class="uk-width-expand label">
								<span class="navigation-side-label uk-padding-small">Dashboard</span>
							</div>
						</div>
					</RouterLink>
				</div>
			</div>

			<DashboardsNavigationLink />

		</div>
	</nav>
</template>
