<script>
import NavLink from '~/helpers/navmodule.js'

export default {
    setup() {
        const auth = useAuthStore();
        const module = useModuleStore();
        const can = auth.can;
        return { auth, can, module };
    },
    components: NavLink,
    data() {
        return {
            navigations: [
                {
                    title: 'App & Setting',
                    permission: 'setting',
                    children: [
                        {
                            title: 'Employee',
                            link: '/dashboard/employees',
                            icon: 'UsersIcon',
                            permission: 'module.employee',
                        },
                        {
                            title: 'Company',
                            link: '/dashboard/companies',
                            icon: 'BuildingSkyscraperIcon',
                            permission: 'module.company',
                        },
                        {
                            title: 'Module',
                            link: '/dashboard/settings/modules',
                            icon: 'AppsIcon',
                            permission: 'setting.module',
                        },
                        {
                            title: 'Accessibility',
                            link: '/dashboard/settings/accessibilities',
                            icon: 'CloudLockIcon',
                            permission: 'setting.accessibility',
                        },
                    ],
                },
            ]
        }
    },
    methods: {
        dashboardLink(value) {
            if (this.$options.components && this.$options.components[`${value.studlyName}DashboardLink`]) {
                return `${value.studlyName}DashboardLink`
            } else {
                return `${value.lowerName}-dashboard-link`
            }
            // return `${value.studlyName}DashboardLink`
        }
    },
}
</script>

<template>
    <ul uk-nav class="uk-width-1-1">
        <template v-for="value in module.module" :key="value.name">
            <component :is="dashboardLink(value)" v-if="value.isEnabled" />
        </template>

        <template v-for="(tab, index) in navigations" :key="index">
            <li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
                <span class="navigation-side-label"><b>{{ tab.title }}</b></span>
            </li>
            <template v-for="tab in tab.children" :key="tab.link">
                <li v-if="can(`${tab.permission}-index`)" class="link"
                    :class="{ 'active': `${$route.path}`.search(tab.link) >= 0 }">
                    <RouterLink :to="tab.link">
                        <span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
                            <component v-bind:is="tab.icon" />
                        </span>
                        <span class="navigation-side-label">{{ tab.title }}</span>
                    </RouterLink>
                </li>
            </template>
        </template>
    </ul>
</template>
