const NavLink = {};

const StudlyLink = import.meta.glob('/modules/*/Vue/components/*DashboardLink.vue', { eager: true })
for (const name in StudlyLink) {
    const path = name.replace(/(\.\/|\.vue)/g, "");
    const filename = path.substring(path.lastIndexOf('/') + 1);
    NavLink[filename] = StudlyLink[name].default;
}

const KebabLink = import.meta.glob('/modules/*/Vue/components/*dashboard-link.vue', { eager: true })
for (const name in KebabLink) {
    const path = name.replace(/(\.\/|\.vue)/g, "");
    const filename = path.substring(path.lastIndexOf('/') + 1);
    NavLink[filename] = KebabLink[name].default;
}

export default NavLink
