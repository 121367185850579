export const useLayoutStore = defineStore('layout', {
    state: () => ({
        layout: 'default',
        appName: import.meta.env.VITE_APP_NAME,
        appDesc: import.meta.env.VITE_APP_DESC
    }),

    actions: {
        setLayout(layout) {
            this.layout = layout
        }
    }
});
