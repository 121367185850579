<script>
export default {
    props: {
        type: String,
    },
    setup() {
        const auth = useAuthStore();
        const can = auth.can;
        return { can };
    },
    data() {
        return {
            navigations: [
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <template v-for="(tab, index) in navigations" :key="index">
        <li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
            <span class="navigation-side-label"><b>{{ tab.title }}</b></span>
        </li>
        <template v-for="tab in tab.children" :key="tab.link">
            <li v-if="can(`${tab.permission}-index`)" class="link"
                :class="{ 'active': $route.path.search('employee') > 0 }">
                <RouterLink :to="tab.link">
                    <span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
                        <component v-bind:is="tab.icon" />
                    </span>
                    <span class="navigation-side-label">{{ tab.title }}</span>
                </RouterLink>
            </li>
        </template>
    </template>
</template>
