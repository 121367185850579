<script>
export default {
    mounted() {
        this.$router.push('/dashboard')
    },
}
</script>

<template>

</template>
